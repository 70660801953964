.about{
    width: 100%;
}

.about .about_h2{
    font-size: 32px;
    font-weight: 600;
    color: #000000;
    line-height: 51px;
}

.about .about_p{
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
}

@media(max-width: 991px){
    .about .about_h2{
        font-size: 24px;
        line-height: 31px;
    }

    .about .about_p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}

@media(max-width: 500px){
    .about .about_h2{
        font-size: 22px;
        line-height: 28px;
    }

    .about .about_p{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }
}


