.tairixy{
    width: 100%;
}
.h1_tairixiy{
    font-size: 36px;
    font-weight: 600;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 24px;
}


.second_poisk{
    margin-top: -166px;
}

@media(max-width: 1399px){
    .second_poisk{
        margin-top: -156px;
    }
}
@media(max-width: 1199px){
    .second_poisk{
        margin-top: -146px;
    }
}
@media(max-width: 991px){
    .second_poisk{
        margin-top: 0;
    }
}
