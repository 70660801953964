.home{
    width: 100%;
    padding: 0px 10px;
}

body{
    background-image: url("../../componentes/images/Rectangle 3463481.png");
}



.home .h1_home{
    font-size: 36px;
    font-weight: 600;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 24px;
    
}

.cards{
    -webkit-box-shadow: 1px 1px 8px 3px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 1px 1px 8px 3px rgba(34, 60, 80, 0.2);
    box-shadow: 1px 1px 8px 3px rgba(34, 60, 80, 0.2);
    border-radius: 5px;
    width: 100%;
    margin-bottom: 100px;
    padding-bottom: 120px;
    background-color: #ffffff;
}


.fro_top{
    background-color: #E0E9FF;
}

.fro_top .col-lg-1{
    border-radius: 5px 0 0 0;
}

.fro_top .col-lg-2{
    border-radius: 0 5px 0 0;
}

.col-lg-1{
    border-right: 1px solid #DBDBDB;
    border-bottom: 1px solid #DBDBDB;
}

.col-lg-3{
    border-right: 1px solid #DBDBDB;
    border-bottom: 1px solid #DBDBDB;
}

.col-lg-2{
    border-bottom: 1px solid #DBDBDB;
}

.fro_top p{
    padding: 10px;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
}

.row p{
    margin-bottom: 0;
}

.for_btttn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.row .for_num{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cards .for_as{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    color: #222222;
    padding: 20px;
}

.arabian{
    color:  #B5179E !important;
}

.btn_batafsil{
    color: #E0E9FF;
    background-color: #3A0CA3;
    border: none;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
    border-radius: 99px;
    padding: 10px 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 0.4s ease-in;
}

@media(max-width: 1399px){
    .btn_batafsil{
        padding: 10px 40px;
    }
}
@media(max-width: 1199px){
    .btn_batafsil{
        color: 14px;
        padding: 8px 30px;
    }
}

@media(max-width: 500px){
    .btn_batafsil{
        color: 14px;
        padding: 10px 80px;
    }

    .btn_batafsil{
        font-size:18px;

    }
 
  
}


.btn_batafsil img{
    width: 15px;
    height: 15px;
}

.btn_batafsil:hover{
    background-color: #E0E9FF;
    color: #3A0CA3;
}

.btn_batafsil:hover img{
    fill: #3A0CA3;
    color: #3A0CA3;
}




.statistika{
    padding: 0 10px;
    width: 100%;    
    background-color: #ffffff;
    margin-bottom: 100px;
}

.statistika .col-6:nth-child(1){
    border-right: 1px solid #DBDBDB;
}

.for_stats{
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background-color: #ffffff;
    padding: 40px 30px;
}

.name_statistika{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.qadimiy{
    color: #2B2C60;
    font-size: 16px;
    font-weight: 400;
}

.for_leftt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.animat{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
}

.h2_text{
    margin: auto;
}

.fr_1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.for_statistika_anima img{
    width: 100%;
}

.for_statistika_anima{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    margin: auto;
    position: relative;
}


.big_dig{
    font-size: 24px;
    font-weight: 400;
    position: absolute;
    margin-left: -45px;
    margin-top: 10px;
}

.ResultModal {
    transition: all .4s ease-in-out;
    
    
}

.recharts-wrapper{
    margin: auto;
}



.row_1 {    
            width: 100%;
            position: fixed;
            top: 50% !important;
            left: 51.5%;
            right: 0;
            background-color: red;
            transform: translate(-50%, -50%);            
           
            padding: 30px;
            z-index: 999999;
            margin: auto;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-image: url("../../componentes/images/modal.jpeg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
}

.for_inp_svg{
    position: relative;
}

.svg_ab{
    position: absolute;
    font-size: 24px;
    color: gray;
    top: 20px;
    left: 25px;
}

@media(max-width: 990px){
    .row_1{
        position: absolute;
        top: 700px !important;
    }
}

@media(max-width: 576px){
    .row_1{
        position: absolute;
        top:800px !important;
    }
}

.row_items h2{
  
}

@media(max-width:440px){
        .row_1{
            margin-left: -7px !important;
        }
}
@media(max-width:420px){
    .row_1{
        margin-left: -7px !important;
    }
}
@media(max-width:398px){
    .row_1{
        margin-left: -5px !important;
    }
}
@media(max-width:376px){
    .row_1{
        margin-left: -5px !important;
    }
}
@media(max-width:360px){
    .row_1{
        margin-left: -4px !important;
    }
}




.for_top_modal{
    width: 100%;
    position: relative;
    z-index: 999999999;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.react_iconss{
    font-size: 24px;
}

.for_bgg img{
    position: absolute;
    right: 20px;
    
    top: 100px;
}

.for_bgg img{
    width: 25%;
}

.for_arab_name{
    color:#B5179E;
}

.row_items h2{
    font-size: 20px;
    color:  #3A0CA3;
    margin-bottom: 15px;
}

.for_bgg{
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: rgba(247, 247, 247, 0.926);
}

.for_bgg{

}
.row_items{
    border-top: 1px solid gray;
    padding: 20px;
}

.modal_name{
    text-align: center;
    margin-bottom: 20px;
}

.shadow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(10, 10, 10, 0.461);
    z-index: 22222;
}

.card2{
    display: none;
}

@media(max-width: 992px){
.cards{
    display: none;
}

.h1_home{
    text-align: center;
}

.card2{
    padding: 0;
    margin: 0;
    display: block;
    /* background-color: red; */
    margin-bottom: 50px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    border-radius: 12px;
    -webkit-box-shadow: 0px -2px 10px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px -2px 10px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px -2px 10px 0px rgba(34, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 50px;
    margin: 0;
    margin-bottom: 20px;
}

.statistika{
    margin-top: 50px;
}

.card2 .for_brd{
    width: 100%;
    border-bottom: 1px solid #DBDBDB;
}

.p_texxx{
    font-size: 14px;
    color:  #3A0CA3;
    font-weight: 700;
}

.for_nameee{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.for_arab_name{
    color: #b5179e !important;
}

.for_arab_card2{
    font-size: 16px;
    color: #B5179E !important;
    font-weight: 400;
}
.col-lg-2 {
    border: none;
}

.for_brd{
    padding: 10px 0;
}

.for_bta_2{
    padding: 20px 0;
}

}

.second_poisk{
    margin-top: -166px !important;
}

@media(max-width: 1399px){
    .second_poisk{
        margin-top: -156px !important;
    }
}
@media(max-width: 1199px){
    .second_poisk{
        margin-top: -146px !important;
    }
}
@media(max-width: 991px){


    .second_poisk{
        margin-top: 180px !important;
    }
}

@media(max-width: 767px){
   .search_etxt{
    left: 140px;
   }
}

@media(max-width: 400px){
   .home .h1_home {
        font-size: 22px;
    }
}

.for_agiantion{
    display: none;
}


@media(max-width: 991px){
    
.for_agiantion{
    display: block;
}
}