.Navbar{
    width: 100%;
    background: #3A0CA3;
    padding: 10px 0 10px 0;
    position:relative;
}

.Navbar a{
    text-decoration: none;
    color: #ffffff;
    transition: 0.4s ease-in-out;
}



.left_navbar .nav_text_1:hover{
    transform: scale(1.1);
    color: #E9E9EC;
}

.nav_text_1.active2{
    text-decoration: underline;
}

.nav_text_2.active2{
    margin-top: 100px!important;
    color: #ffffff;
    background-color: transparent;
}

.left_navbar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

.telegramm{
    border-radius: 50%;
    padding: 5px 10px;
    background-color: #ffffff;
    color: #3A0CA3;
    font-size: 18px;
    cursor: pointer;

}

.telegramm:hover .iconn{

}

.for_qadimiy{
    width: 320px;
}

@media(max-width: 506px){
    
.left_navbar .nav_text{
    font-size: 13px;    
}
}
@media(max-width: 437px){
    
.left_navbar .nav_text{
    line-height: 25px;  
}
}

.Navbar .col-3{
    border: none;
}

.right_navbar{
    display: flex;
    justify-content:flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 6px;
}

.right_navbar img{
    width: 10%;
}

.navbar_bottom{
    width: 100%;
    background: #ffffff;
    border-bottom: 1px solid #E9E9EC;
    padding: 16px 0;
    margin-bottom: 50px;
    transition: 0.4s ease-in
}

.top_navbar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    position:relative;
    z-index: 99999;
   
}

.top_navbar img{
        width: 25%;
}

.navbar_bottom .col-3, .navbar_bottom .col-2{
    border: none;
}

.poisk{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.poisk input{
    border: none;
    background: #E9E9EC;
    border-radius: 99px;
    padding: 19px 60px;
    outline: none;
}

.nav_text{
    color: #2B2C60;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
}

.right_navbar select{
    border-radius: 20px;
    padding: 9px 9px 9px 16px;
    gap: 4px;
    font-size: 16px;
    color: #3A0CA3;
    outline: none;
}

.right_navbar option {
    padding: 2px;
    border: 1px solid #3a0ca3;
    color: #3A0CA3;
}

.search_etxt{
    font-size: 24px;
    color: gray;
    position: relative;
    right: 253px;
    top: 10px;
}

.nav__list{
    padding: 0;
    margin-top:3px;
}



  .nav__listitem {
      margin: 0;
      padding: 0;
    list-style: none;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    color: #ffffff;
    cursor: pointer;
    margin-top: 15px;
  }

  .nav__listitem:hover::after, .nav__listitem:focus::after {
    width: 80%;
  }
  .nav__listitem:hover ul, .nav__listitem:focus ul {
    opacity: 1;
    visibility: visible;
  }
  .nav__listitemdrop {
    position: absolute;
    top: 1.5rem;
    left: -1rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
    -webkit-box-shadow: 0px -1px 9px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px -1px 9px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px -1px 9px 0px rgba(34, 60, 80, 0.2);
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 14px;
    font-weight: 300;
    opacity: 0;
    z-index: 999999;
    visibility: hidden;
    transition: opacity 200ms ease-in-out;
  }

  .nav_a_box{
      display: none;
  }


  .nav__listitemdrop li {
    list-style: none;
    color: #000000;
    font-weight: 400;
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    transition: background-color 200ms ease-in-out;
  }
  .nav__listitemdrop li:hover, .nav__listitemdrop li:focus {
    background-color: #3A0CA3; 
    color: #ffffff;
  }


  @media(max-width: 1399px){
      .nav_text_1{
          font-size: 13px;
      }
  }
  @media(max-width: 1199px){
      .nav_text_1{
          font-size: 12px;
      }
      .right_navbar{
          margin-top: 20px;
      }

  }



  @media(max-width: 991px){
      .poisk{
          justify-content: center;
          margin-bottom: 20px;
      }

      .navbar_bottom{
        position: fixed;
        z-index: 9999999;

      }
      .nav__list{
           margin-top: -15px; 

      }

      .search_etxt{
          left: 230px;
      }

      .Navbar{
          display: none;
      }
  }

  @media(max-width: 767px){
    .search_etxt{
        left: 150px;
    }
    .nav_text{
        font-size: 16px !important;
        line-height: 24px !important;
    }
  }
  @media(max-width: 550px){
    .search_etxt{
        left: 10px;
    }
  }
  @media(max-width: 528px){
    .nav_text {
        font-size:12px !important;
        line-heigh:18px !important;
    }
    .search_etxt{
        left: 130px;
    }
  }

  @media(max-width: 411px){
    .nav_text {
        width: 300px;
        font-size: 12px!important;
        line-height: 15px !important;
    }

    .for_qadimiy{
        /* width: 700px; */
        /* background: red; */
    }
  }
  @media(max-width: 509px){
    .search_etxt{
        left: 125px;
    }
  }
  @media(max-width: 496px){
    .search_etxt{
        left: 115px;
    }
  }
  @media(max-width: 478px){
    .search_etxt{
        left: 100px;
    }
  }
  @media(max-width: 453px){
    .search_etxt{
        left: 100px;
    }
  }
  @media(max-width: 443px){
    .search_etxt{
        left: 90px;
    }
  }
  @media(max-width: 431px){
    .search_etxt{
        left: 80px;
    }
  }
  @media(max-width: 411px){
    .search_etxt{
        left: 70px;
    }
  }
  @media(max-width: 401px){
    .search_etxt{
        left: 60px;
    }
  }
  @media(max-width:363px){
    .search_etxt{
        left: 55px;
    }
  }


  
    @media(max-width:991px) {
    .nav_a_box{
        width: 100%;
        height: 100%;
        background-color: #3a0ca3;
        display: block;
        visibility: hidden;
        position: fixed !important;
        z-index: 999999;
        left: 0;
        top: 0;
        transition: all 0.3s ease-in;
        transform: translate(120px);
        
    }

    .nav_a_box .left_navbar{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        margin-top: 300px;
    }

    .for_obshiy_mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .for_sosse{
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav_a_box .left_navbar a{
        color: #ffffff;
        text-decoration: none;
    }

     .active2{
         
        position: absolute;
        top: 0;
        left: 0;
        background-color: #3a0ca3;
        visibility: visible;
        transform:  translate(10px);
    }
}



.burger {
    width: 30px;
    height: 30px;
    margin-right: 25px;
    transition: all .5s ease-in-out;
    z-index: 999999;
    position: fixed;
    top: 55px;
    right: 3%;
    transform: translateY(-50%);
}


    .burger_up,
    .burger_down {
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: #000000;
        border-radius: 12px;
        transition: all .5s ease-in;
    }

    .burger_up {
        left: -8px;
        width: 50px;
        top: 0;
    }

    .burger_down {
        top: 12px;
        transition: all .3s ease-in-out;
    }

  
    .burgered .burger_up {
            transform: translateY(10px) rotate(45deg);
            background-color: #FFFFFF;
        }

        .burgered  .burger_down {
            transform: translate(12px,-2px) rotate(-45deg);
            background-color: #FFFFFF;

        }

        .burgered  .burger_up {
            width: 30px;
            left: 14px;
        }

        .for_burger{
            font-size: 24px;
            z-index: -1;
        }

