.page-item.active .page-link{
    /* background-color: red !important; */
    background-color: transparent!important;
    padding: 6px 13px !important;
    border-radius: 50% !important;
    border: 1px solid #3A0CA3 !important;
    color: #3A0CA3!important;
    transition: 0.4s ease-in;
}

.react-hooks-paginator{
    gap: 5px!important;
}

.page-link:hover{
    background-color: #3a0ca3!important;
    padding: 6px 13px !important;
    border-radius: 50% !important;
    border: 1px solid #ffffff !important;
    color: #ffffff!important;

}

.page-link{
    margin-top: 50px !important;
    /* padding: 0 10px!important; */
    padding: 6px 13px !important;
    border-radius: 50% !important;
    color: #000000 !important;

}