.footer{
    width: 100%;
    background: linear-gradient(0deg, #3A0CA3, #3A0CA3);
border-radius: 65px 65px 0px 0px;
    padding-top: 76px;
    padding-bottom: 76px;

}

.top_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.top_footer p{
    margin-left: 15px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.aloqa{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}

.aloqa li{
    list-style-type: none;
    color: #ffffff;
    margin-bottom: 25px;
    font-size: 17px;
    font-weight: 300;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.aloqa li a{
    text-decoration: none;
    color: #ffffff;
    margin-left: 10px;
}

.aloqa li p{
    margin-left: 10px;
}

.aloqa .aloqa_text{
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

.for_sos{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    gap: 5px;
    width: 100%;   
}


.for_sos li{
    background-color: #ffffff;
    border-radius: 23px;
    padding: 10px 23px;
    list-style-type: none;
}

@media(max-width: 1199px){
    .for_sos li{
        background-color: #ffffff;
        border-radius: 23px;
        padding: 6px 10px;
        list-style-type: none;
    }
}



.for_sos li .fv_con{
    margin: 0 6px;
}


.hover {
    --c:linear-gradient(#ffffff 0 0);
    padding-bottom: .15em;
    background: var(--c), var(--c);
    background-size: .3em .1em;
    background-position:50% 100%;
    background-repeat: no-repeat;
    transition: .3s linear, background-size .3s .2s linear;
  }
  .hover:hover {
    background-size: 40% .1em;
    background-position: 10% 100%, 90% 100%;
  }
  
  

  